import Container from 'index.style';
import React, { useState } from 'react';
import {
  InvestorBox,
  InvestorBoxText,
  InvestorListCard,
  InvestorCard,
} from './InvestorBlock.style';
import OkeyIcon from 'assets/img/OkeyIcon.png';
import SafeIcon from 'assets/img/SafeIcon.png';
import { useTranslation } from 'react-i18next';

// Основной компонент
export const InvestorBlock = () => {
  // Локализация
  const { t } = useTranslation();

  // Состояние
  const [activeCard, setActiveCard] = useState<number | null>(1);

  // Данные карточек (можно хранить во внешнем источнике или получать из API)
  const cards = [
    { icon: OkeyIcon, titleKey: 'cardTitleOne', infoKey: 'cardInfoOne' },
    { icon: SafeIcon, titleKey: 'cardTitleTwo', infoKey: 'cardInfoTwo' },
  ];

  // Рендер
  return (
    <Container>
      <InvestorBox>
        {/* Текстовая информация */}
        <InvestorBoxText>
          <div>
            <p>{t('investorBlock.tag')}</p>
            <h3>{t('investorBlock.title')}</h3>
          </div>
        </InvestorBoxText>

        {/* Список карточек */}
        <InvestorListCard>
          {cards.map((card, index) => (
            <InvestorCard
              key={index}
              onClick={() => setActiveCard(index)}
              className={activeCard === index ? 'active' : ''}>
              <img src={card.icon} alt={`icon-${card.titleKey}`} />
              <h3>{t(`investorBlock.${card.titleKey}`)}</h3>
              <p>{t(`investorBlock.${card.infoKey}`)}</p>
            </InvestorCard>
          ))}
        </InvestorListCard>
      </InvestorBox>
    </Container>
  );
};
