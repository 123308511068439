import { useState, useRef, useEffect } from 'react';
import {
  Menu,
  LogoCompany,
  DropdownContainer,
  DropdownContent,
  StyledLink,
  Arrow,
  BoxSwitcher,
} from './Header.style';
import Container from 'index.style';
import { default as Logo } from 'assets/img/LogoMoneyLink.svg';
import { default as MenuOpen } from 'assets/img/Menu.svg';
import { default as MenuClose } from 'assets/img/MenuClose.svg';
import useClickOutside from 'utils/useClickOutside';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { LanguageSwitcher } from 'components/LanguageSwitcher';

const validPaths = ['/data_agreement', '/contact', '/user_agreement'];

export const Header = () => {
  const currentScreen = useLocation();
  const currentPath = currentScreen.pathname;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isOpenInformation, setIsOpenInformation] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleToggleDropdown = () => {
    setIsOpenInformation(!isOpenInformation);
  };

  const handleLinkClick = (event: any, path: any) => {
    event.preventDefault();

    const [pathname, hash] = path.split('#');

    if (currentPath !== pathname) {
      navigate(pathname);
    }

    if (hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }

    setMenuOpen(false);
  };

  useClickOutside(dropdownRef, () => {
    setIsOpenInformation(false);
  });

  useEffect(() => {
    document.body.style.overflow = menuOpen ? 'hidden' : '';
  }, [menuOpen]);

  return (
    <Container>
      <Menu>
        <LogoCompany src={Logo} loading='eager' alt='Logo' />
        <ul className={menuOpen ? 'menu active' : 'menu'}>
          <li>
            <StyledLink to='/' onClick={(e) => handleLinkClick(e, '/')}>
              {t('headerLanding.main')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              onClick={(e) => handleLinkClick(e, '/#api')}
              className={currentPath === '/#api' ? 'current' : 'decoration'}
              to='/#api'>
              {t('headerLanding.business')}
            </StyledLink>
          </li>
          <li>
            <StyledLink
              onClick={(e) => handleLinkClick(e, '/#possibilities')}
              className={currentPath === '/#possibilities' ? 'current' : 'decoration'}
              to='/#possibilities'>
              {t('headerLanding.investor')}
            </StyledLink>
          </li>
          <li>
            <DropdownContainer ref={dropdownRef}>
              <StyledLink
                to='#'
                onClick={handleToggleDropdown}
                className={validPaths.includes(currentPath) ? 'current' : 'decoration'}>
                {t('headerLanding.information')}
                <Arrow data-is-open={isOpenInformation} />
              </StyledLink>

              {isOpenInformation && (
                <DropdownContent className={isOpenInformation ? 'open' : ''}>
                  <ul>
                    <li>
                      <StyledLink
                        onClick={(e) => handleLinkClick(e, '/contact')}
                        className={currentPath === '/contact' ? 'current' : 'decoration'}
                        to='/contact'>
                        {t('headerLanding.informationThree')}
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={(e) => handleLinkClick(e, '/data_agreement')}
                        className={currentPath === '/data_agreement' ? 'current' : 'decoration'}
                        to='/data_agreement'>
                        {t('headerLanding.informationFour')}
                      </StyledLink>
                    </li>
                    <li>
                      <StyledLink
                        onClick={(e) => handleLinkClick(e, '/user_agreement')}
                        className={currentPath === '/user_agreement' ? 'current' : 'decoration'}
                        to='/user_agreement'>
                        {t('headerLanding.informationFive')}
                      </StyledLink>
                    </li>
                  </ul>
                </DropdownContent>
              )}
            </DropdownContainer>
          </li>
        </ul>
        <BoxSwitcher>
          <LanguageSwitcher />
        </BoxSwitcher>
        <div onClick={() => setMenuOpen(!menuOpen)} className='mobile_btn'>
          {menuOpen ? (
            <img src={MenuClose} alt='icon' width={48} height={48} />
          ) : (
            <img src={MenuOpen} alt='icon' width={48} height={48} />
          )}
        </div>
      </Menu>
    </Container>
  );
};
