import { styled } from 'styled-components';
import colors from 'global_styling/colors';

const BoxMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
`;

const ImgBanner = styled.img`
  width: 100%;
  height: 100%;
`;

const TextBox = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 28px;
  max-width: 49.667vw;
  color: ${colors.CHARCOAL};

  @media (max-width: 900px) {
    padding-bottom: 0;
    max-width: 664px;
  }
  @media (max-width: 510px) {
    max-width: 364px;
    top: 15%;
  }

  h3 {
    font-size: 3.854vw;
    font-weight: 700;

    @media (max-width: 900px) {
      font-size: 54px;
    }
    @media (max-width: 700px) {
      font-size: 40px;
    }
  }
  p {
    font-size: 1.042vw;
    font-weight: 400;
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
  span {
    color: ${colors.TEAL};
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export { BoxMain, ImgBanner, TextBox };
