import React from 'react';
import {
  BoxMain,
  TextBox,
  LineBanner,
  Title,
  Paragraph,
  PrivacyPolicyLink,
  BoxContent,
} from '../DataAgreementBlock/DataAgreementBlock.style';
import Container from 'index.style';
import LineBaner from 'assets/img/LineBanner.webp';

export const UserAgreementBlock = () => {
  return (
    <>
      <LineBanner src={LineBaner} alt='line' />
      <Container>
        <BoxMain>
          <TextBox>
            <h3>Пользовательское соглашение</h3>
          </TextBox>
          <BoxContent>
            <Title>1. Общие положения</Title>
            <Paragraph>
              1.1. Настоящее Пользовательское соглашение (далее – Соглашение) определяет условия
              использования интернет-ресурса «Moneylink», расположенного по адресу:
              <PrivacyPolicyLink href='https://moneylink.kz'>
                https://moneylink.kz
              </PrivacyPolicyLink>
            </Paragraph>
            <Paragraph>
              1.2. Права на использование Интернет-ресурса{' '}
              <PrivacyPolicyLink href='https://moneylink.kz'>
                https://moneylink.kz
              </PrivacyPolicyLink>{' '}
              принадлежат ТОО «MoneyLink».
            </Paragraph>
            <Paragraph>
              1.3. Перечисленные ниже термины имеют для целей настоящего Соглашения следующее
              значение:
            </Paragraph>
            <Paragraph>
              1.3.1. Сайт – интернет-ресурс «moneylink.kz», то есть совокупность размещенных в сети
              электронных документов (файлов), объединенных единой темой, дизайном и единым адресным
              пространством домена moneylink.kz. Стартовая страница Сайта размещена в сети Интернет
              по адресу{' '}
              <PrivacyPolicyLink href='https://moneylink.kz'>
                https://moneylink.kz
              </PrivacyPolicyLink>
              ;
            </Paragraph>
            <Paragraph>
              1.3.2. Администратор – ТОО «MoneyLink», которому принадлежат все соответствующие
              имущественные права на Сайт;
            </Paragraph>
            <Paragraph>
              1.3.3. Пользователь – лицо, обладающее необходимой дееспособностью, имеющее доступ к
              Сайту посредством сети Интернет и использующее Сайт;
            </Paragraph>
            <Paragraph>
              1.3.4. Соглашение – настоящее соглашение между Администратором и Пользователем,
              устанавливающее взаимные права и обязанности по использованию Сайта. Текст Соглашения
              размещено по ссылке{' '}
              <PrivacyPolicyLink href='https://moneylink.kz/user_policy'>
                https://moneylink.kz/user_policy
              </PrivacyPolicyLink>
              . Продолжение использования Сайта Пользователем означает принятие Соглашения, при этом
              Соглашение вступает в силу с момента выражения таким образом Пользователем согласия с
              его условиями и действует в течение всего времени предоставления и использования
              услуг;
            </Paragraph>
            <Paragraph>
              1.3.5. Аккаунт – учетная запись Пользователя на Сайте, защищенная паролем, созданная
              им посредством прохождения процедуры регистрации. Содержит информацию о Пользователе и
              Контент, сгенерированный Пользователем. Учетная запись содержит контактные и иные, в
              том числе персональные, данные Пользователя, включая, но не ограничиваясь, такими как
              Ф.И.О. Пользователя/уполномоченного лица Пользователя, контактный телефон, электронный
              адрес, копии документов;
            </Paragraph>
            <Paragraph>
              1.3.6 учетные данные – уникальные логин и пароль Пользователя, созданные при
              регистрации;
            </Paragraph>
            <Paragraph>
              1.3.7. Контент – любое информационно-значимое наполнение Сайта, в том числе в виде
              копий документов, текстов и прочие материалы, оставленные Пользователем на Сайте под
              своим Аккаунтом;
            </Paragraph>
            <Paragraph>
              1.3.8. Содержание Сайта - охраняемые результаты интеллектуальной деятельности, включая
              пользовательские интерфейсы, визуальные интерфейсы, названия товарных знаков,
              логотипы, программы для ЭВМ, базы данных, а также дизайн, структура, выбор,
              координация, внешний вид, общий стиль и расположение указанного и другие объекты
              интеллектуальной собственности все вместе и/или по отдельности, содержащиеся на Сайте;
            </Paragraph>
            <Paragraph>1.3.9. Стороны – Администратор и Пользователь.</Paragraph>
            <Paragraph>
              1.4. Настоящее Соглашение регулирует отношения между Администратором и Пользователем
              Сайта и является публичной офертой согласно статье 395 Гражданского кодекса Республики
              Казахстан (далее – ГК РК). Получая доступ к Сайту, Пользователь считается
              присоединившимся к настоящему Соглашению (п.3 ст. 396 ГК РК). В случае несогласия
              Пользователя с каким-либо из положений Соглашения, Пользователь не вправе использовать
              Сайт.
            </Paragraph>
            <Paragraph>
              1.5. Администратор вправе в одностороннем порядке без предварительного уведомления
              изменить условия настоящего Соглашения с доведением до сведения Пользователя путем
              размещения Соглашения в новой редакции на Сайте. Пользователь обязуется самостоятельно
              регулярно отслеживать изменения в Соглашении. Продолжение использования Сайта
              Пользователем означает принятие Соглашения и изменений, внесенных в настоящее
              Соглашение. При несогласии Пользователя с внесенными изменениями, он обязан прекратить
              использование Сайта.
            </Paragraph>
            <Title>2. Предмет Соглашения</Title>
            <Paragraph>
              2.1. Предметом настоящего Соглашения является предоставление Пользователю доступа к
              содержащейся на Сайте информации (данным) и оказываемым услугам.
            </Paragraph>
            <Paragraph>
              2.2. Пользователю предоставляются следующие виды услуг (сервисов) на Сайте:
            </Paragraph>
            <Paragraph>
              2.2.1. доступ к электронному контенту на бесплатной основе, с правом просмотра
              контента;
            </Paragraph>
            <Paragraph>2.2.2. доступ к средствам поиска и навигации Сайта;</Paragraph>
            <Paragraph>
              2.2.3. доступ к информации о потенциальных сделках по факторингу и иных сделках на
              бесплатной основе;
            </Paragraph>
            <Paragraph>
              2.2.4. иные виды услуг (сервисов), реализуемые на страницах Интернет-ресурса, включая
              платные услуги (сервисы).
            </Paragraph>
            <Paragraph>
              2.3. Под действие настоящего Соглашения подпадают все существующие (функционирующие)
              на данный момент услуги (сервисы) Сайта, а также любые их последующие модификации и
              появляющиеся в дальнейшем дополнительные услуги (сервисы) Сайта.
            </Paragraph>
            <Paragraph>2.4. Доступ к Сайту предоставляется на безвозмездной основе.</Paragraph>
            <Title>3. Права и обязанности Сторон</Title>
            <Paragraph>
              3.1. <b>Администратор обязан:</b>
            </Paragraph>
            <Paragraph>
              3.1.1. Предоставить Пользователю доступ к Сайту в соответствии с условиями настоящего
              Соглашения;
            </Paragraph>
            <Paragraph>
              3.1.2. Оказывать Пользователю услуги в соответствии с условиями настоящего Соглашения,
              а также условиями договоров, заключенных между Сторонами.
            </Paragraph>
            <Paragraph>
              3.2. <b>Администратор вправе:</b>
            </Paragraph>
            <Paragraph>
              3.2.1. Изменять правила пользования Сайтом, а также изменять содержание Сайта.
              Изменения вступают в силу с момента публикации новой редакции Соглашения на Сайте;
            </Paragraph>
            <Paragraph>3.2.2. Оказывать услуги, в том числе платные, на Сайте;</Paragraph>
            <Paragraph>
              3.2.3. Ограничить доступ к Сайту в случае нарушения Пользователем условий настоящего
              Соглашения;
            </Paragraph>
            <Paragraph>
              3.2.4. Осуществлять сбор, накопление, обработку, в том числе анализ, использование,
              распространение (в том числе на платной основе) информации о Пользователе,
              содержащейся на Сайте, включая, но не ограничиваясь, информацией о контактных и личных
              данных Пользователя, информацией о действиях Пользователя на Сайте, на основе
              Соглашения о данных, заключенного Сторонами.
            </Paragraph>
            <Paragraph>
              3.3. <b>Пользователь вправе:</b>
            </Paragraph>
            <Paragraph>3.3.1. Получить доступ к использованию Сайта;</Paragraph>
            <Paragraph>
              3.3.2. Пользоваться всеми имеющимися на Сайте услугами в соответствии с условиями
              настоящего Соглашения;
            </Paragraph>
            <Paragraph>
              3.3.3 Задавать вопросы касательно услуг Администратора на Сайте в соответствии с
              порядком использования Сайта;
            </Paragraph>
            <Paragraph>
              3.3.4. Пользоваться Сайтом исключительно в целях и порядке, предусмотренных
              Соглашением и не запрещенных законодательством Республики Казахстан.
            </Paragraph>
            <Paragraph>
              3.4. <b>Пользователь Сайта обязуется:</b>
            </Paragraph>
            <Paragraph>
              3.4.1. Соблюдать условия использования Сайта, закрепленные в настоящем Соглашении;
            </Paragraph>
            <Paragraph>
              3.4.2. Предоставлять по запросу Администратора дополнительную информацию, которая
              имеет непосредственное отношение к оказываемым на Сайте услугам;
            </Paragraph>
            <Paragraph>
              3.4.3. Соблюдать имущественные и неимущественные права авторов и иных правообладателей
              при использовании Сайта;
            </Paragraph>
            <Paragraph>
              3.4.4. Не предпринимать действий, которые могут рассматриваться как нарушающие
              нормальную работу Сайта;
            </Paragraph>
            <Paragraph>
              3.4.5. Не распространять с использованием Сайта любую конфиденциальную и охраняемую
              законодательством Республики Казахстан информацию о физических либо юридических лицах,
              а также избегать любых действий, в результате которых может быть нарушена
              конфиденциальность охраняемой законодательством Республики Казахстан информации;
            </Paragraph>
            <Paragraph>
              3.4.6. Не использовать Сайт для распространения информации рекламного характера, иначе
              как с согласия Администратора;
            </Paragraph>
            <Paragraph>
              3.4.7. Не использовать сервисы Сайта с целью: загрузки Контента, который является
              незаконным, нарушает права третьих лиц; пропагандирует насилие, жестокость, ненависть
              и (или) дискриминацию по расовому, национальному, половому, религиозному, социальному
              признакам; содержит недостоверные сведения и (или) оскорбления в адрес конкретных лиц,
              организаций, органов власти; побуждения к совершению противоправных действий, а также
              содействия лицам, действия которых направлены на нарушение ограничений и запретов,
              действующих на территории Республики Казахстан; представления себя за другого человека
              или представителя организации и (или) сообщества без достаточных на то прав, в том
              числе за представителей Администратора; оказания без лицензии услуг, относящихся к
              лицензируемым видам деятельности в соответствии с законодательством Республики
              Казахстан.
            </Paragraph>
            <Title>4. Порядок использования Сайта</Title>
            <Paragraph>
              4.1. Пользователям без регистрации доступен ограниченный пакет функций Сайта. Для
              получения доступа к полному пакету услуг требуется регистрация. Регистрация
              Пользователя на Сайте осуществляется с использованием электронной цифровой подписи
              Пользователя, выданной Национальным удостоверяющим центром Республики Казахстан.
            </Paragraph>
            <Paragraph>
              4.2. Администратор предоставляет Пользователю возможность подписания документов на
              Сайте электронной цифровой подписью Пользователя, выданной Национальным удостоверяющим
              центром Республики Казахстан.
            </Paragraph>
            <Paragraph>
              4.3. При регистрации на Сайте Пользователь подтверждает, что вся предоставляемая им на
              Сайте информация является правдивой, точной, актуальной и полной; при приобретении
              услуг для третьих лиц, от имени которых Пользователь имеет право осуществлять данные
              действия, Пользователь должен информировать этих лиц обо всех условиях, правилах и
              ограничениях выбранной услуги.
            </Paragraph>
            <Paragraph>
              4.4. Администратор исходит из того, что любые действия, совершенные через Аккаунт,
              считаются совершенными Пользователем, поэтому Пользователь обязуется:
            </Paragraph>
            <Paragraph>
              4.4.1. следить за конфиденциальностью и безопасностью учетных данных и данных
              Аккаунта;
            </Paragraph>
            <Paragraph>4.4.2. не передавать свои учетные данные третьим лицам;</Paragraph>
            <Paragraph>
              4.4.3. незамедлительно уведомить Администратора о несанкционированном доступе в его
              Аккаунт;
            </Paragraph>
            <Paragraph>
              4.4.4. незамедлительно изменить учетные данные при наличии оснований считать, что их
              конфиденциальность может быть нарушена.
            </Paragraph>
            <Paragraph>
              4.5. Пользователь должен незамедлительно уведомить Администрацию сайта о
              несанкционированном использовании его учётной записи или пароля либо любом другом
              нарушении системы безопасности.
            </Paragraph>
            <Paragraph>
              4.6. Администратор обеспечивает конфиденциальность и сохранность персональных данных
              Пользователя. При регистрации Аккаунта Пользователь обязуется подписать Соглашение о
              данных. Сбор, обработка, использование, хранение, защита и распространение
              персональных данных осуществляются в строгом соответствии с законодательством
              Республики Казахстан о персональных данных и их защите.
            </Paragraph>
            <Paragraph>
              4.7. Администратор размещает на Сайте перечень предлагаемых Услуг, а также публичные
              оферты в отношении предлагаемых услуг.
            </Paragraph>
            <Paragraph>
              4.8. Администратор имеет право в любое время без уведомления Пользователя вносить
              изменения в перечень услуг, предлагаемых на Сайте.
            </Paragraph>
            <Paragraph>
              4.9. Инструкции для Пользователей размещены на сайте moneylink.kz.
            </Paragraph>
            <Paragraph>
              4.10. <b>Пользователю запрещается:</b>
            </Paragraph>
            <Paragraph>
              4.10.1. использовать любые устройства, программы, процедуры, алгоритмы и методы для
              дублирования, копирования, модифицикации Сайта;
            </Paragraph>
            <Paragraph>4.10.2. нарушать надлежащее функционирование Сайта;</Paragraph>
            <Paragraph>
              4.10.3. получать доступ, использовать Аккаунты других Пользователей, подписывать
              документы электронной цифровой подписью другого лица;
            </Paragraph>
            <Paragraph>
              4.10.4. любым способом обходить навигационную структуру Сайта для получения или
              попытки получения любой информации, документов или материалов любыми средствами,
              которые специально не представлены сервисами данного Сайта;
            </Paragraph>
            <Paragraph>
              4.10.5. несанкционированный доступ к функциям Сайта, любым другим системам или сетям,
              относящимся к данному Сайту, а также к любым услугам, предлагаемым на Сайте;
            </Paragraph>
            <Paragraph>
              4.10.6. нарушать систему безопасности или аутентификации на Сайте или в любой сети,
              относящейся к Сайту;{' '}
            </Paragraph>
            <Paragraph>
              4.10.7. выполнять обратный поиск, отслеживать или пытаться отслеживать любую
              информацию о любом другом Пользователе Сайта;
            </Paragraph>
            <Paragraph>
              4.10.8. использовать Сайт и его содержание в любых целях, запрещенных
              законодательством Республики Казахстан, а также подстрекать к любой незаконной
              деятельности или другой деятельности, нарушающей права Администратора или других лиц;
            </Paragraph>
            <Paragraph>
              4.10.9. распространять спам, любую другую навязчивую информацию, а также информацию,
              нарушающую права третьих лиц.
            </Paragraph>
            <Paragraph>
              4.11. Информация, размещаемая Пользователем на Сайте, не должна истолковываться как
              изменение настоящего Соглашения.
            </Paragraph>
            <Paragraph>
              4.12. Администратор имеет право раскрыть информацию о Пользователе, если действующее
              законодательство Республики Казахстан или Соглашение о данных, заключенное между
              Сторонами, требует или разрешает такое раскрытие.
            </Paragraph>
            <Paragraph>
              4.13. Содержание Сайта не может быть скопировано, опубликовано, воспроизведено,
              передано или распространено любым способом, а также размещено в глобальной сети
              Интернет без предварительного письменного согласия Администратора.
            </Paragraph>
            <Paragraph>
              4.14. Содержание Сайта защищено авторским правом, законодательством о товарных знаках,
              а также другими правами, связанными с интеллектуальной собственностью, и
              законодательством о недобросовестной конкуренции.
            </Paragraph>
            <Paragraph>
              4.15. Пользователь согласен с тем, что Администратор оставляет за собой право
              заблокировать/удалить Аккаунт Пользователя на Сайте и/или приостановить, ограничить
              или прекратить доступ Пользователя к Сайту, если Администратор обнаружит в действиях
              Пользователя признаки нарушения условий настоящего Соглашения, без объяснения причин
              таких действий и без предварительного уведомления Пользователя, однако Администратор
              приложит все возможные усилия для исключения подобных ситуаций.
            </Paragraph>
            <Paragraph>
              4.16. Пользователь вправе в любое время удалить Аккаунт, используя интерфейс удаления
              Аккаунта.
            </Paragraph>
            <Paragraph>
              4.17. Администратор вправе без предварительного уведомления Пользователя прекратить и
              (или) заблокировать доступ к Сайту в случае прекращения действия Сайта либо по причине
              технической неполадки или проблемы.
            </Paragraph>
            <Paragraph>
              4.18. Администратор обладает правом в одностороннем порядке аннулировать Аккаунт
              Пользователя, если он не использовался более 6 (шести) календарных месяцев подряд, без
              уведомления Пользователя.
            </Paragraph>
            <Title>5. Ответственность</Title>
            <Paragraph>
              5.1. Администратор прилагает все возможные усилия для того, чтобы исключить с Сайта
              небрежную, неаккуратную, оскорбительную, не соответствующую действительности или
              заведомо неполную информацию, вместе с тем, ответственность за нее лежит на
              разместивших ее лицах.
            </Paragraph>
            <Paragraph>
              5.2. Администратор не отвечает за то, что зарегистрированные пользователи Сайта
              являются действительно теми лицами, за кого себя выдают, и не несет ответственности за
              возможный ущерб, причиненный другим лицам вследствие этого.
            </Paragraph>
            <Paragraph>
              5.3. Пользователь несет персональную ответственность за сохранение конфиденциальности
              информации Аккаунта, включая пароль, электронную цифровую подпись, а также за всю без
              исключения деятельность, которая ведется от имени Пользователя на Сайте.
            </Paragraph>
            <Paragraph>5.4. Администратор не несет ответственности за:</Paragraph>
            <Paragraph>
              5.4.1. содержание и достоверность информации, предоставленной Пользователем при
              регистрации и использовании Сайта;
            </Paragraph>
            <Paragraph>
              5.4.2. задержки или сбои в процессе совершения операций на Сайте, возникшие вследствие
              непреодолимой силы, а также любого случая неполадок в телекоммуникационных,
              компьютерных, электрических и иных смежных системах;
            </Paragraph>
            <Paragraph>
              5.4.3. действия систем переводов, банков, платежных систем и за задержки, связанные с
              их работой;
            </Paragraph>
            <Paragraph>
              5.4.4. надлежащее функционирование Сайта в случае, если Пользователь не имеет
              необходимых технических средств для его использования, а также не несет никаких
              обязательств по обеспечению Пользователя такими средствами;{' '}
            </Paragraph>
            <Paragraph>5.4.5.Контент Пользователей;</Paragraph>
            <Paragraph>
              5.4.6. убытки, любой прямой, косвенный, неумышленный ущерб, включая упущенную выгоду
              или потерянные данные, вред чести, достоинству или деловой репутации, понесенные
              Пользователем вследствие использования Сайта или результатов интеллектуальной
              деятельности, размещенных на Сайте, заказа услуг на Сайте, в случае, если иное не
              установлено соглашением между Сторонами;
            </Paragraph>
            <Paragraph>
              5.4.7. негативные последствия и убытки, связанные с невозможностью Пользователя
              использовать услуги на Сайте в результате предоставления недостоверных или
              недостаточных сведений или документов, их непредоставления или несвоевременного
              предоставления, а также в результате нарушения Пользователем положений настоящего
              Соглашения, действующего законодательства и иных применимых требований;
            </Paragraph>
            <Paragraph>
              5.4.8. незнание и/или несоблюдение Пользователем и любыми третьим лицами норм
              действующего законодательства Республики Казахстан.{' '}
            </Paragraph>
            <Paragraph>
              5.5. Администратор не несет ответственности перед Пользователем за прекращение доступа
              к Сайту в случае нарушения Пользователем любого положения настоящего Соглашения.
            </Paragraph>
            <Paragraph>
              5.6. Пользователь самостоятельно несет ответственность за интерпретацию и
              использование Контента, а также за все действия, совершаемые им с использованием
              Сайта.
            </Paragraph>
            <Paragraph>
              5.7. В случае предъявления третьими лицами претензий к Администратору, связанных с
              использованием Пользователем Сайта, Пользователь обязуется своими силами и за свой
              счет урегулировать указанные претензии с третьими лицами, оградив Администратора от
              возможных убытков и разбирательств.
            </Paragraph>
            <Title>6. Заключительные положения</Title>
            <Paragraph>
              6.1. Все споры, возникающие из Соглашения или связанные с ним, могут быть переданы на
              разрешение суда после принятия Сторонами мер по досудебному урегулированию спора по
              истечении 30 (тридцати) календарных дней со дня направления первой претензии. Если
              спор не урегулирован в досудебном порядке согласно настоящему пункту, он может быть
              передан на разрешение суда в соответствии с действующим законодательством Республики
              Казахстан по месту нахождения Администратора.
            </Paragraph>
            <Paragraph>
              6.2. Использование материалов и сервисов Сайта, а также настоящее Соглашение
              регулируются нормами законодательства Республики Казахстан.
            </Paragraph>
            <Paragraph>
              6.3. Юридически значимые сообщения в адрес Администратора направляются в электронном
              виде по адресу (e-mail):{' '}
              <PrivacyPolicyLink href='mailto:info@moneylink.kz'>
                info@moneylink.kz
              </PrivacyPolicyLink>
              .
            </Paragraph>
          </BoxContent>
        </BoxMain>
      </Container>
    </>
  );
};
