import { styled } from 'styled-components';
import BusinessBanner from 'assets/img/BusinessBannerBlock.webp';
import { ReactComponent as IconArrow } from 'assets/img/IconArrowRight.svg';
import colors from 'global_styling/colors';

const ListCardBusiness = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 80px;
  gap: 50px;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const BannerBoxBusiness = styled.div`
  width: 100%;
  justify-content: center;
  background-image: url(${BusinessBanner});
  background-size: cover;
  border-radius: 36px;
`;

const BannerBoxText = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors.WHITE};
  padding: 74px 66px 66px 54px;
  span {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }
  h3 {
    font-size: 44px;
    font-weight: 700;
    margin-top: 214px;
    margin-bottom: 42px;
    @media (max-width: 510px) {
      font-size: 28px;
      margin-top: 114px;
    }
  }
  p {
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
  }
`;

const InfoBoxBusiness = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 28px;
`;

const InfoCardBusiness = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    transition: width 0.8s;
  }

  &::before {
    width: 100%;
    background: ${colors.BORDER_GREY};
  }

  &::after {
    width: 0;
    background: ${colors.TEAL};
  }

  &:hover::after {
    width: 100%;
  }

  &:hover h4 {
    color: ${colors.TEAL};
  }

  h4 {
    color: ${colors.CHARCOAL};
    font-size: 24px;
    font-weight: 600;
    align-items: center;
    display: flex;
    justify-content: space-between;
    @media (max-width: 940px) {
      max-width: 314px;
    }
    @media (max-width: 900px) {
      max-width: 100%;
    }
  }

  p {
    color: ${colors.GRAY};
    font-size: 20px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 24px;
    max-width: 484px;
  }
`;

const Arrow = styled(IconArrow)`
  position: absolute;
  margin-top: 2px;
  right: 0;
  padding-left: 2px;
`;

export {
  ListCardBusiness,
  BannerBoxBusiness,
  BannerBoxText,
  InfoBoxBusiness,
  InfoCardBusiness,
  Arrow,
};
