import { StyledLink } from 'components/Footer/Footer.style';
import React, { ReactNode, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type ScrollToTopLinkProps = {
  to: string;
  children: ReactNode;
};

const ScrollToTopLink: React.FC<ScrollToTopLinkProps> = ({ to, children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault();

    const [path, hash] = to.split('#');

    if (location.pathname !== path) {
      navigate(path);
    }

    if (hash) {
      setTimeout(() => {
        const targetElement = document.getElementById(hash);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
  };

  return (
    <StyledLink to={to} onClick={handleClick}>
      {children}
    </StyledLink>
  );
};

export default ScrollToTopLink;
