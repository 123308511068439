import Container from 'index.style';
import LogoVector from 'assets/img/LogoVector.webp';
import { InfoBlockBox, InfoBoxText } from './InfoBlock.style';

interface InfoBlockProps {
  tag: string;
  title: string;
  description: string;
  button: string;
}

export const InfoBlock = ({ tag, title, description }: InfoBlockProps) => {
  return (
    <Container id='api'>
      <InfoBlockBox>
        <img src={LogoVector} alt='logo-vector' loading='lazy' />
        <InfoBoxText>
          <p>{tag}</p>
          <div>
            <h3>{title}</h3>
            <span>{description}</span>
          </div>
        </InfoBoxText>
      </InfoBlockBox>
    </Container>
  );
};
