import { StyledLink } from 'components/Footer/Footer.style';
import React, { ReactNode } from 'react';

type ScrollToTopLinkProps = {
  to: string;
  children: ReactNode;
};

const ScrollFooter: React.FC<ScrollToTopLinkProps> = ({ to, children }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <StyledLink to={to} onClick={handleClick}>
      {children}
    </StyledLink>
  );
};

export default ScrollFooter;
