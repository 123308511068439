import { FooterBox, InfoBox, BoxRow, MiniFooter, Copyright, Line } from './Footer.style';
import Container from 'index.style';
import { default as Logo } from 'assets/img/LogoMoneyLink.svg';
import { useTranslation } from 'react-i18next';
import ScrollToTopLink from 'utils/ScrollToTopLink';
import ScrollFooter from 'utils/ScrollFooter';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <FooterBox>
      <Container>
        <BoxRow>
          <InfoBox className='first'>
            <img src={Logo} alt='logo' />
          </InfoBox>
          <InfoBox>
            <h4> {t('footer.titleOne')}</h4>
            <ul>
              <li>
                <ScrollToTopLink to='/'>{t('footer.menuOne')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/#api'>{t('footer.menuTwo')}</ScrollToTopLink>
              </li>
              <li>
                <ScrollToTopLink to='/#possibilities'>{t('footer.menuThree')}</ScrollToTopLink>
              </li>
            </ul>
          </InfoBox>
          <InfoBox>
            <h4> {t('footer.titleTwo')}</h4>
            <ul>
              <li>
                <ScrollFooter to='/contact'>{t('footer.menuSeven')}</ScrollFooter>
              </li>
              <li>
                <ScrollFooter to='/data_agreement'>{t('footer.menuEight')}</ScrollFooter>
              </li>
              <li>
                <ScrollFooter to='/user_agreement'>{t('footer.menuNine')}</ScrollFooter>
              </li>
            </ul>
          </InfoBox>
        </BoxRow>
      </Container>
      <Line />
      <Container>
        <MiniFooter>
          <Copyright> {t('footer.copyright')}</Copyright>
        </MiniFooter>
      </Container>
    </FooterBox>
  );
};
