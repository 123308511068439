import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import LandingScreen from 'screens/LandingScreen/LandingScreen';
import BusinessScreen from 'screens/BusinessScreen/BusinessScreen';
import { Header } from 'components/Header';
import { Footer } from 'components/Footer';
import InvestorScreen from 'screens/InvestorScreen/InvestorScreen';
import AgentScreen from 'screens/AgentScreen/AgentScreen';
import ContactScreen from 'screens/ContactScreen/ContactScreen';
import DataAgreementScreen from 'screens/DataAgreementScreen/DataAgreementScreen';
import { CookiesAccept } from 'components/CookiesAccept';
import UserAgreementScreen from 'screens/UserAgreementScreen/UserAgreementScreen';

function App() {
  return (
    <div>
      <Header />
      <CookiesAccept />
      <Routes>
        <Route path='/' element={<LandingScreen />} />
        {/* <Route path='business' element={<BusinessScreen />} />
        <Route path='investor' element={<InvestorScreen />} />
        <Route path='agent' element={<AgentScreen />} /> */}
        <Route path='contact' element={<ContactScreen />} />
        <Route path='data_agreement' element={<DataAgreementScreen />} />
        <Route path='user_agreement' element={<UserAgreementScreen />} />
      </Routes>
      <Footer />
    </div>
  );
}
export default App;
